<template>
    <div>
        <page-title :heading="$t('erp.lang_voucher') " :subheading="$t('erp.lang_voucher')" :icon=icon></page-title>
        <div class="app-main__inner">
        <voucher></voucher>
        </div>
    </div>
</template>

<script>
    import PageTitle from "../../../Layout/Components/PageTitle.vue";
    import Voucher from "../../../components/erp/baseData/Vouchers";

    export default {
        components: {
            PageTitle,
            Voucher
        },

        data: () => ({
            icon: 'pe-7s-gift icon-gradient bg-tempting-azure',
        })
    }
</script>